import { useEffect } from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'
import { parseContent } from '@components/Blocks'

import FlexSection from '@components/FlexSection'
import FlexIcon from '@components/FlexIcon'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_IconWithText4Columns,
  WpPrograma_Acfpagefields_Content_IconWithText4Columns
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_IconWithText4Columns
  | WpPrograma_Acfpagefields_Content_IconWithText4Columns

const IconWithText4Columns = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, columns, splitIntoLines, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      index={index}
      tabs={tabs}
      id={title || ''}
      alignTitle="center"
      paddingTop={title ? true : false}
      paddingBottom={splitIntoLines ? false : true}
    >
      {text && (
        <div tw="prose mx-auto mb-6 text-center!">
          {text && parse(text, { replace: parseContent })}
        </div>
      )}
      <div tw="flex flex-wrap justify-between py-16">
        {columns &&
          columns.map((column, index) => {
            const iconColors = findColors(column.svgIcon.iconColor)
            return (
              <div
                key={index}
                tw="w-full mb-6 md:mb-0 md:w-1/2 text-center md:px-6 lg:px-10"
                css={[splitIntoLines ? tw`lg:w-1/2 pb-10` : tw`lg:w-1/4`]}
              >
                <div tw="text-center mb-6">
                  <FlexIcon
                    css={[iconColors.svg]}
                    name={column.svgIcon.iconType}
                    tw="w-16 mx-auto"
                  />
                </div>
                {column.title && (
                  <h3 tw="font-medium uppercase mb-2">{column.title}</h3>
                )}
                {column.text && <p tw="leading-relaxed">{column.text}</p>}
              </div>
            )
          })}
      </div>
    </FlexSection>
  )
}

export default IconWithText4Columns
